<template>
  <div>
    <LiveVideoModal ref="LiveVideoModal" />
    <SearchFilter :BRANCH_OPTS="branch_list" :EVENT_TYPES="EVENT_TYPES" @change="onFilterChange" />
    <EdxTableWrapper
      :items="list"
      :fields="FIELDS"
      header-icon="cil-bell"
      :add-btn="false"
      :count="count"
      :caption="$t('cloud.title.alarm')"
      hover
      small
      fixed
      :checkable="false"
      @video="onVideo"
      @staff="onStaff"
    />

    <CRow class="justify-content-end" v-if="pageTotal > 1">
      <CPagination
        :activePage.sync="pageCurrent"
        :pages="pageTotal"
        @update:activePage="onUpdatePage"
      >
      </CPagination>
      <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
    </CRow>
    <TopBtn />
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import ListItem from './ListItem.vue'
import SearchFilter from './SearchFilter.vue'
import TopBtn from '@/components/TopBtn.vue'
import EdxTableWrapper from '@/components/EdxTable'
import LiveVideoModal from '@/components/modals/LiveVideoModal'

export default {
  name: 'ParkingEvents',

  components: {
    SearchFilter,
    ListItem,
    TopBtn,
    EdxTableWrapper,
    LiveVideoModal
  },
  data () {
    return {
      loading: false,
      confirm_text: null,

      place_list: [],

      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      filters: {},
      count: 0,
      list: [],

      branch_list: [],
      EVENT_TYPES: [
        {id: 'enter_exit', name: 'Entry/Exit'},
        {id: 'fire', name: 'Fire'},
        {id: 'illegal_parking', name: 'Illegal Parking'},
        {id: 'garbage_dumping', name: 'Garbage Dumping'},
        {id: 'unpaid_exit', name: 'Unpaid Exit'},
        {id: 'vandalism', name: 'Vandalism'},
        {id: 'wearing', name: 'Wearing'}
      ],

      FIELDS: [
        {key: 'fmt_time', label: 'Time', _classes: 'align-middle'},
        {key: 'service_name', label: 'Service', _classes: 'align-middle'},
        {key: 'branch_name', label: 'Branch', _classes: 'align-middle'},
        {key: 'event_name', label: 'Event type', _classes: 'align-middle'},
        {key: 'attrs', label: 'Attributes', _classes: 'align-middle'},
        {key: 'more_parking', label: 'Operation', _style:'width: 80px;'}
      ],
    }
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.getBranchList();
      this.getList();
    },
    getList() {
      let query_string = '';
      let params = this.buildParam(10, this.pageCurrent);

      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      this.loading = true;

      this.list = [];
      axios.get(`/api/events/${query_string}`)
        .then(result => {
          this.list = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
          this.list.map(el => {
            el.fmt_time = this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(el.ts));
            el.event_name = this.EVENT_TYPES.find(e => e.id === el.event_type).name;
            // el.attr = `${el.index_column2}, ${el.index_column3}`;
            el.service_name = el.service_key ? this.$t(`cloud.title.service_${el.service_key}`):'';
            el.attrs = [el.index_column1, el.index_column2, el.index_column3];
          })
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading = false;
        })
    },
    onUpdatePage() {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    buildParam(ipp, page) {
      let params = {
        ipp: ipp,
        page: page
      }
      if (this.filters.service) params.service = this.filters.service;
      if (this.filters.branch) params.branch = this.filters.branch;
      if (this.filters.event_type) params.event_type = this.filters.event_type;
      if (this.filters.ts_mode) {
        if (this.filters.ts_mode === 'today') {
          let now = new Date();
          now.setHours(0);
          now.setMinutes(0);
          now.setSeconds(0);
          now.setMilliseconds(0);
          params.sts = now.getTime();
        } else if (this.filters.ts_mode === 'd1') {
          let yesterday = new Date(Date.now() - (1000 * 60 * 60 * 24));
          yesterday.setHours(0);
          yesterday.setMinutes(0);
          yesterday.setSeconds(0);
          yesterday.setMilliseconds(0);
          params.sts = yesterday.getTime();
        } else if (this.filters.ts_mode === 'd2') {
          let yesterday = new Date(Date.now() - (1000 * 60 * 60 * 24 * 2));
          yesterday.setHours(0);
          yesterday.setMinutes(0);
          yesterday.setSeconds(0);
          yesterday.setMilliseconds(0);
          params.sts = yesterday.getTime();
        } else if (this.filters.ts_mode === 'h24') {
          params.sts = Date.now() - (1000 * 60 * 60 * 24);
        }
      } else if (this.filters.sts && this.filters.ets) {
        params.sts = this.filters.sts;
        params.ets = this.filters.ets;
      }
      return params;
    },
    getBranchList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 100,
        page: 1
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
    },
    onFilterChange(payload) {
      this.filters = payload;
      this.getList();
    },

    onVideo(item) {
      this.$refs.LiveVideoModal.open({
        device_id: item.device_id,
        ch: item.ch
      });
    },
    onPlace(item) {
      let branch_id = item.branch_id;
      let place = this.place_list.find(el => el.branch_id === branch_id);
      this.$router.push(`/service/parking/places/${place.id}`);
    },
    onStaff(item) {
      this.$alert('Event notification has been sent', 'Notification', {
        type: 'success'
      });
    }
  }
}
</script>

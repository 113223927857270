<template>
  <div>
    <DatePickerModal ref="DatePickerModal" title="Time Range" ok_text="OK" cancel_text="Cancel" @ok="onTsTimestamps" />
    <CCard v-if="!loading">
      <CCardHeader v-if="filters.length">
        <div class="filter_row">
          <div class="filter_label d-md-down-none" style="border: 0;"></div>
          <div class="ml-4 my-1">
            <el-tag class="mr-1" v-for="f in filters" :key="`filter-tags-${f.id}`" :type="f.type" closable @close="onClose(f.id)">{{f.content}}</el-tag>
          </div>
        </div>
      </CCardHeader>
      <CCardBody>
        <CButton v-if="collapse" @click="onFold"
          color="secondary"
          size="sm"
          class="px-3 filter_detail_onoff">
          <svgicon name="keyboard_arrow_up" width="18px" height="18px" />
        </CButton>
        <CButton v-else @click="onUnfold"
          color="secondary"
          size="sm"
          class="px-3 filter_detail_onoff">
          <svgicon name="keyboard_arrow_down" width="18px" height="18px" />
        </CButton>
        <CRow>
          <CCol md="12" lg="8">
            <div class="filter_row">
              <div class="filter_label d-md-down-none">{{$t('cloud.title.event_types')}}</div>
              <el-select class="ml-4 my-1 filter_input_size" v-model="filter_type" :placeholder="$t('cloud.title.all_event_types')" filterable clearable @change="onFilterChange">
                <el-option
                  v-for="event_type in EVENT_TYPES"
                  :key="`types-opts-${event_type.id}`"
                  :label="event_type.name"
                  :value="event_type.id">
                </el-option>
              </el-select>
            </div>
            <CCollapse :show="collapse">
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.filter_branch')}}</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filter_branch" :placeholder="$t('cloud.title.all_branch')" filterable clearable @change="onFilterBranch">
                  <el-option-group
                    v-for="group in regions"
                    :key="`regions-${group.id}`"
                    :label="group.name">
                    <el-option
                      v-for="option in group.options"
                      :key="`branch-${option.id}`"
                      :label="option.name"
                      :value="option.id">
                    </el-option>
                  </el-option-group>
                </el-select>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.filter_service')}}</div>
                <el-select class="ml-4 my-1 filter_input_size" v-model="filter_service" :placeholder="$t('cloud.title.all_service')" filterable clearable @change="onFilterChange">
                  <el-option
                    v-for="(service, index) in capability.services"
                    :key="`service-${index}`"
                    :label="$t(`cloud.title.service_${service.key}`)"
                    :value="service.key">
                  </el-option>
                </el-select>
              </div>
              <div class="filter_row">
                <div class="filter_label d-md-down-none">{{$t('cloud.title.time_range')}}</div>
                  <el-tooltip class="ml-4 my-1" effect="dark" :content="$t('cloud.title.filter_ts_today')" placement="top">
                    <el-button :type="filter_ts_mode === 'today'?'primary':''" circle @click="onTsToday">
                      <svgicon name="today" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_yesterday')" placement="top">
                    <el-button :type="filter_ts_mode === 'd1'?'primary':''" circle @click="onTsMinus1">
                      <svgicon name="exposure_neg_1" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_before_yesterday')" placement="top">
                    <el-button :type="filter_ts_mode === 'd2'?'primary':''" circle @click="onTsMinus2">
                      <svgicon name="exposure_neg_2" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip class="mx-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_last_24_hours')" placement="top">
                    <el-button :type="filter_ts_mode === 'h24'?'primary':''" circle @click="onTs24Hours">
                      <svgicon name="replay_circle_filled" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                  <div style="border-left: 1px solid #eee;">
                  <el-tooltip class="ml-2 my-1" effect="dark" :content="$t('cloud.title.filter_ts_manual')" placement="top">
                    <el-button type="info" circle @click="onTsManual">
                      <svgicon name="access_time" width="16px" height="16px"></svgicon>
                    </el-button>
                  </el-tooltip>
                </div>
              </div>
            </CCollapse>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DatePickerModal from '@/components/modals/DatePickerModal.vue';

export default {
  name: 'ParkingSearchFilter',
  props: {
    BRANCH_OPTS: {type: Array, default: () => {return [];}},
    EVENT_TYPES: {type: Array}
  },
  components: {
    DatePickerModal
  },

  data() {
    return {
      loading: false,

      filter_service: null,
      filter_branch: null,
      filter_type: null,
      filter_ts_mode: null,
      filter_sts: null,
      filter_ets: null,

      collapse : false
    }
  },

  mounted() {
    this.getSearchFilter();
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    filters() {
      let rtn = [];
      if (this.filter_branch) {
        let branch = this.BRANCH_OPTS.find(el=>el.id===this.filter_branch);
        if (branch) {
          rtn.push({
            id: 'branch',
            type: 'primary',
            content: `Place:${branch.name}`
          });
        }
      }
      if (this.filter_type) {
        let event_type = this.EVENT_TYPES.find(el => el.id === this.filter_type);
        if (event_type) {
          rtn.push({
            id: 'event_type',
            type: 'primary',
            content: `Types:${event_type.name}`
          });
        }
      }
      if (this.filter_ts_mode) {
        if (this.filter_ts_mode === 'today') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: `Time:Today`
          });
        } else if (this.filter_ts_mode === 'd1') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: `Time:From yesterday`
          });
        } else if (this.filter_ts_mode === 'd2') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: `Time:From -2 days`
          });
        } else if (this.filter_ts_mode === 'h24') {
          rtn.push({
            id: 'ts_mode',
            type: 'primary',
            content: `Time:Last 24 hours`
          });
        }
      }
      if (this.filter_sts && this.filter_ets) {
        rtn.push({
          id: 'ts',
          type: 'info',
          content: `Time: ${this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.filter_sts))}~${this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.filter_ets))}`
        });
      } 
      return rtn;
    },
    regions() {
      let rtn = [];
      for (const branch of this.BRANCH_OPTS) {
        let rtn_region = rtn.find(el => el.name === branch.region_name);
        if (!rtn_region) {
          rtn.push(
            {
              name: branch.region_name,
              id: branch.region,
              options: [
                {name: branch.name, id: branch.id}
              ]
            }
          )
        } else {
          rtn_region.options.push({name: branch.name, id: branch.id});
        }
      }
      rtn = rtn.sort((a,b) => {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        else return 0;
      });
      return rtn;
    }
  },
  methods: {
    getSearchFilter() {
    },
    putSearchFilter() {
    },
    onFilterChange() {
      let payload = {
        service: this.filter_service,
        branch: this.filter_branch,
        event_type: this.filter_type,
        ts_mode: this.filter_ts_mode,
        sts: this.filter_sts,
        ets: this.filter_ets
      };
      this.$emit('change', payload);
      this.putSearchFilter();
    },
    onFilterBranch() {
      this.filter_correspondent = null;
      this.filter_origin = null;
      this.onFilterChange();
    },
    onTsToday() {
      this.filter_sts = null;
      this.filter_ets = null;
      this.filter_ts_mode = 'today';
      this.onFilterChange();
    },
    onTsMinus1() {
      this.filter_sts = null;
      this.filter_ets = null;
      this.filter_ts_mode = 'd1';
      this.onFilterChange();
    },
    onTsMinus2() {
      this.filter_sts = null;
      this.filter_ets = null;
      this.filter_ts_mode = 'd2';
      this.onFilterChange();
    },
    onTs24Hours() {
      this.filter_sts = null;
      this.filter_ets = null;
      this.filter_ts_mode = 'h24';
      this.onFilterChange();
    },
    onTsManual() {
      this.$refs.DatePickerModal.open();
    },
    onTsTimestamps({sts, ets}) {
      this.filter_ts_mode = null;
      this.filter_sts = sts;
      this.filter_ets = ets;
      this.onFilterChange();
    },

    onClose(filter_id) {
      switch(filter_id) {
        case 'branch':
          this.filter_branch = null;
          break;
        case 'event_type':
          this.filter_type = [];
          break;
        case 'ts_mode':
          this.filter_ts_mode = null;
          this.filter_sts = null;
          this.filter_ets = null;
          break;
        case 'ts':
          this.filter_ts_mode = null;
          this.filter_sts = null;
          this.filter_ets = null;
          break;
      }
      this.onFilterChange();
    },

    onFold() {
      this.collapse = false;
    },
    onUnfold() {
      this.collapse = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.filter_row {
  display:flex;
  flex-direction:row;
  flex-wrap: wrap;
  height:50px;
}
.filter_label {
  width:120px;
  margin-right:10px;
  line-height:45px;
  border-right:1px solid #d8dbe0;
  font-weight: bold;
}
.filter_select {
  width:150px;
  margin-top : 5px;
  margin-left:10px;
}
.filter_picker {
  margin-top : 2.3px;
  margin-left:10px;
}
.filter_input_size {
  width: 250px;
}
.filter_detail_onoff {
  position:absolute;
  left:50%;
  bottom:-14px;
  margin-left:-26px;
}
.filter_tags {
  position: absolute;
  left: 500px;
  top: 24px;
}
</style>

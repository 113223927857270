<template>
  <div class="custom-table-row">
    <div class="custom-table-line">
      <!--- 시간 --->
      <div class="custom-table-column-short">{{transformTimestampToDate}}</div>
      <!--- 주차장 --->
      <div class="custom-table-column-normal">{{row.branch_name}}</div>
      <!--- 이벤트 유형 --->
      <div class="custom-table-column-short">{{eventType}}</div>
      <!--- 이벤트 속성 --->
      <div class="custom-table-column-normal">{{row.index_column2}}, {{row.index_column3}}</div>
      <!--- 차량번호 --->
      <div class="custom-table-column-short">
        {{ row.index_column4 || '' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParkingListItem',

  props: {
    row: {type: Object, default: null},
    EVENT_TYPES: {type: Array}
  },
  data() {
    return {
      force_expand: false
    }
  },
  computed: {
    transformTimestampToDate(){
      return this.$utility.GetDateTimeStr('$yyyy-$mm-$dd $HH:$MM:$ss', new Date(this.row.ts));
    },
    eventType() {
      let et = this.EVENT_TYPES.find(el => el.id === this.row.event_type);
      return et.name;
    }
  }
}
</script>
